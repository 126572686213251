import React from 'react'

function History(props) {
  return (
    <>
    <section className="content">
        <h2 className='con-head'>{props.title}</h2>
        <br/><br/>
        <h3>Overview</h3><br/>
        <p style={{color:'black'}}>The Jaats are a historically non-elite group of herders and peasants in Northern India.
          Following their rebellion against the Mughal Emperor Aurangzeb and the establishment of a powerful kingdom at Bharatpur, the Jaats developed in the 17th century. 
          The Mathura region saw the beginning of the Jaats’ first great uprising against Mughal imperial soldiers in 1669.
          The several Jaat tribes were merged into a single vast empire under the leadership of Suraj Mal, the only Jaat leader.</p><br/><br/>
          <h3>Jaats History</h3><br/>
          <p style={{color:'black'}}>The Jaats started fighting the Mughal Empire in the late 17th and early 18th century. The organisation was crucial to the creation of Sikhism’s military Khalsa panth. 
            The Hindu Jaat kingdom was ruled at its height by Maharaja Suraj Mal (1707–1763). 
            The land-owning Jaats became a dominant force in Punjab, Western Uttar Pradesh, Rajasthan, Haryana, and Delhi by the 20th century.

            Throughout the years, a number of Jaats have given up farming in favour of urban employment, asserting higher social standing as a result of their prevailing economic and political strength. 
            These nascent peasant-warrior societies weren’t well-established Indian castes; rather, they were new, lacked clear status distinctions, 
            and had the capacity to incorporate earlier peasant castes, numerous warlords, and nomadic tribes that were living on the periphery of settled agriculture.

            Even at its height of power, the Mughal Empire never had direct control over the rural grandees. Instead, authority was distributed. 
            These zamindars gained the most from the uprisings, strengthening their control over the region. Even lesser kings, like Badan Singh, 
            the Jaat ruler of the princely state of Bharatpur, were elevated to positions of power by the winners.</p><br/><br/>
            <h3>Jaats Rise</h3><br/>
            <p style={{color:'black'}}>In the late seventeenth and early eighteenth century, the Jaats increased their authority. 
            Under Churaman’s direction, they enlarged their lands to the west of Delhi. 
            They had gained dominance over the area between Delhi and Agra, two imperial towns, by the 1680s. 
            They served as the city of Agra’s de facto guardians for a while.

            The Jaats were skilled farmers, and while they were in power, towns like Panipat and Ballabgarh developed into significant trading hubs. 
            Suraj Mal led the kingdom of Bharatpur to become a powerful state.</p>
            <br/><br/>
            <h3>Jaats Leader</h3><br/>
            <h4>1. Gokla</h4>
            <p style={{color:'black'}}>He was the Tilpat zamindar and gave the Jaats leadership during their insurrection in 1669 AD. The Mughal governor, Hasan Ali, killed him.</p><br/><br/>
            <h4>2. Rajarama</h4>
            <p style={{color:'black'}}>He led the Jaat insurrection in 1685 AD while serving as the zamindar of Sinsana. Raja Bishan Singh Kachwaha of Amber killed him.</p><br/><br/>
            <h4>3. Churaman</h4>
            <p style={{color:'black'}}>He was Rajaram’s nephew, who in AD 1704 seized Sinsani and overthrew the Mughals. In addition to creating the state of Bharatpur and assisting the Mughals in their campaign against Banda Bahadur, Bahadur Shah handed him Mansab.</p><br/><br/>
            <h4>4. Badan Singh</h4>
            <p style={{color:'black'}}>He was Churaman’s nephew. Ahmed Shah Abdali bestowed upon him the title of “Raja.” He was regarded as the true founder of Bharatpur’s Jaat state.</p><br/><br/>
            <h4>5. Suraj Mal</h4>
            <p style={{color:'black'}}>He was Badan Singh’s adopted son. Because he establishes the Jaat kingdom at its pinnacle, he is known as “the Plato of the Jaat tribe” and as “Jaat Ulysses.” He was in charge of the expedition in the Agra, Mewar, and Delhi provinces when the Marathas requested his assistance in the third Battle of Panipat. He was killed by the Pathans close to Delhi.</p>
    </section><br/><br/><br/><br/><br/>
    
    </>
  )
}

export default History
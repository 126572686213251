import React from 'react'

function Err() {
  return (
    <>
    
    <h1 style={{display:'flex', justifyContent:'center', alignItems:'center', height:'60vh'}}>404 Page Not Found!</h1>

    </>
  )
}

export default Err
import React from 'react'

function Footer() {
  return (
    <>
    
    <footer className='sticky-footer'>
        <p>&copy; 2023 Jaat Samaj Community</p>
    </footer>
    
    </>

  )
}

export default Footer
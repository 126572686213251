import React from 'react'

function Members(props) {
  return (
    <>
    
    <br/><br/>  
    <h2 className='con-head'>{props.title}</h2>
    
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <table style={{ border: '1px solid black', borderCollapse: 'collapse', width:'1600px', margin:'50px' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px' }}>Members</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Mobile</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Spouse</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Address</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Childrem</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Education</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Shri. R.V. Singh</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>9810283360</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Smt. Seema Devi</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>AB-25, Ansal Golf Link, Greater Noida</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Mamta Chaudhary<br/>Rahul CHaudhary<br/>Shubham Chaudhary</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>B.Com.<br/>M.B.A. <br/>10th</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Shri Satish Kumar</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>8800230075</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Smt. Nirmesh</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>A-89, Alpha-1, Greater Noida</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Pooja Chauhan<br/>Kajal</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>B.Tech.<br/>B.B.A.</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Shri Dilip Singh Chahar</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>9350846853</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Smt. Sarojlata</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>B-1, Alpha-1, Greater Noida</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Nirupama<br/>Ajay</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>M.A.<br/>B.Tech.</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Shri Dinesh Kumar Tewatia</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>9899184671</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Smt. Rashmi</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>B-141, Alpha-1, Greater Noida</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Khushi<br/>Rashi</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>1st<br/>5th</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Shri Devendra Singh</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>9971405717</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Smt. Kushum Singh</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>B-177, Alpha-1, Greater Noida</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Mohini Singh<br/>Shivam Singh</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>4th<br/>5th</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px' }}>Shri. Umesh Singh</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>9811327279</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Smt. Preeti</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>B-205, Alpha-1, Greater Noida</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>Nikita<br/>Yashi</td>
            <td style={{ border: '1px solid black', padding: '8px' }}>2nd<br/>4th</td>
          </tr>
         
        </tbody>
      </table>
      <div style={{height:'200px'}}></div>
    </div>

    </>
  )
}

export default Members

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import myImage from './Jaat-Samaj.png';
import './Header.css';

function Header(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const linkStyle = {
    textDecoration: 'none',
    position: 'relative',
  };

  const underlineAnimation = {
    content: '""',
    position: 'absolute',
    width: '0%',
    height: '2px',
    bottom: '0',
    left: '0',
    backgroundColor: 'goldenrod',
    transition: 'width 0.3s',
  };

  const handleMouseEnter = (e) => {
    const underlineElement = e.target.querySelector('.underline');
    if (underlineElement) {
      underlineElement.style.width = '100%';
    }
  };

  const handleMouseLeave = (e) => {
    const underlineElement = e.target.querySelector('.underline');
    if (underlineElement) {
      underlineElement.style.width = '0%';
    }
  };

  return (
    <>
      <header>
        <h1>{props.title}</h1>
        <div className="logo">
          <img src={myImage} alt="jaat-samaaj-logo" />
        </div>
        <nav className={menuOpen ? 'menu-open' : ''}>
          <div className="menu-toggle" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul>
            <li>
              <Link
                to="/"
                className="nav1"
                style={linkStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                Home
                <div className="underline" style={underlineAnimation}></div>
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="nav1"
                style={linkStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                About Us
                <div className="underline" style={underlineAnimation}></div>
              </Link>
            </li>
            <li>
              <Link
                to="/history"
                className="nav1"
                style={linkStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                History
                <div className="underline" style={underlineAnimation}></div>
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="nav1"
                style={linkStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                Contact Us
                <div className="underline" style={underlineAnimation}></div>
              </Link>
            </li>
            <li>
              <Link
                to="/gallery"
                className="nav1"
                style={linkStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                Photo Gallery
                <div className="underline" style={underlineAnimation}></div>
              </Link>
            </li>
            <li>
              <Link
                to="/members"
                className="nav1"
                style={linkStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                Our Members
                <div className="underline" style={underlineAnimation}></div>
              </Link>
            </li>
            <li>
              <Link
                to="/goals"
                className="nav1"
                style={linkStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                Our Goals
                <div className="underline" style={underlineAnimation}></div>
              </Link>
            </li>
            <li>
              <Link
                to="/adminform"
                className="nav1"
                style={linkStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                Admin Form
                <div className="underline" style={underlineAnimation}></div>
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Header;
